import { graphql } from 'gatsby';
import React from 'react';
import Heimdall from '../src/components/Heimdall/Heimdall';
import { Odin } from '../src/components/Odin/Odin';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import Uthred from '../src/components/Uthred/Uthred';
import H2 from '../src/components/Titles/TitleH2/TitleH2';
import tt from '../src/helpers/translation';
import Layout from '../src/components/Layout/Layout';
import Icon from '../src/helpers/icon';
import Hod from '../src/components/Hod/Hod';
import materialGrafico from '../src/icons/material-grafico';
import SEO from '../src/helpers/seo';

import './ficha_material_grafico.scss';

export const pageQuery = ({ data, pathContext: { locale } }) => graphql`
  query ficha_materialQuery($section_name: String!, $locale: String!) {
    allFichaMaterialSeoBlock(filter: { lang: { eq: $locale }, tag: { eq: $section_name } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allFichaMaterialTitle(filter: { lang: { eq: $locale }, tag: { eq: $section_name } }) {
      edges {
        node {
          title
          description
        }
      }
    }
    allFichaMaterialImageHeaderBlock(
      filter: { lang: { eq: $locale }, tag: { eq: $section_name } }
    ) {
      edges {
        node {
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allFichaMaterialBreadcrumbBlock(filter: { lang: { eq: $locale }, tag: { eq: $section_name } }) {
      edges {
        node {
          name
        }
      }
    }
    allFichaMaterialOdin(filter: { lang: { eq: $locale }, tag: { eq: $section_name } }) {
      edges {
        node {
          ctaText
          cta
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allFichaMaterialImageList(filter: { lang: { eq: "es" }, tag: { eq: $section_name } }) {
      edges {
        node {
          imageArr
        }
      }
    }
    allFichaMaterialPopUp(filter: { lang: { eq: $locale }, tag: { eq: $section_name } }) {
      edges {
        node {
          title
          body
          ctaText
        }
      }
    }
    allFichaMaterialRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
          }
        }
      }
    }
  }
`;

class FichaMaterialGrafico extends React.Component {
  state = {};

  retrieveImageList = (array) =>
    array.map((e) => (
      <div className="ma-photo">
        <img src={e} alt="img" />
        <div className="photo-icon">
          <a href={e} download>
            <Icon
              icon="get_app"
              width="30"
              height="30"
              class="download-icon"
              iconSet={materialGrafico}
            />
          </a>
        </div>
      </div>
    ));

  render() {
    const heimdallData = {
      name: this.props.data.allFichaMaterialTitle.edges[0].node.title,
      image: {
        url: this.props.data.allFichaMaterialImageHeaderBlock.edges[0].node.localImage
          .childImageSharp.fluid,
      },
    };
    let titleOdin;
    if (this.props.pageContext.section_name === 'b-e') {
      titleOdin = 'business & events';
    } else if (this.props.pageContext.section_name === 'hotel') {
      titleOdin = tt('hoteles PortAventura', this.props.pageContext.locale);
    } else if (this.props.pageContext.section_name === 'aquatic-park') {
      titleOdin = 'Caribe Aquatic Park';
    } else {
      titleOdin = this.props.pageContext.section_name.replace('-', ' ');
    }
    const odinData = {
      image: this.props.data.allFichaMaterialImageList.edges[0].node.imageArr[0],
      alt: 'BUSINESS & EVENTS',
      title: titleOdin.toUpperCase(),
      rssImg: true,
      buttons: {
        cta1: this.props.data.allFichaMaterialOdin.edges[0].node.cta,
        ctaText1: this.props.data.allFichaMaterialOdin.edges[0].node.ctaText,
        size: 'alone',
        color1: 'orange',
      },
    };
    const h2Data = {
      title: this.props.data.allFichaMaterialTitle.edges[0].node.description,
    };

    return (
      <Layout
        layoutFromOld
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
        options={this.props.pageContext.options}
      >
        <SEO
          title={this.props.data.allFichaMaterialSeoBlock.edges[0].node._0.title}
          description={this.props.data.allFichaMaterialSeoBlock.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <Hod
          data={this.props.data.allFichaMaterialRichData.edges[0].node.rich_markup}
          url={this.props.pageContext.url}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <Uthred data={this.props.data.allFichaMaterialPopUp.edges[0].node} />
          <div className="general-index">
            <div className="graphic-container">
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                title={this.props.data.allFichaMaterialBreadcrumbBlock.edges[0].node.name}
                options={this.props.pageContext.options}
              />
              <H2 data={h2Data} />
              <Odin data={odinData} locale={this.props.pageContext.locale} />
              <div className="gallery-photos">
                {this.retrieveImageList(
                  this.props.data.allFichaMaterialImageList.edges[0].node.imageArr
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default FichaMaterialGrafico;
